<template>
  <el-dialog
    :title="id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    custom-class="dialog-warpper"
  >
    <el-card>
      <div slot="header">车辆基本信息</div>

      <el-form :model="dataForm" :rules="dataRule" ref="dataFormDom">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="所属部门">
            <template slot="label">
              <span class="requireSyb">*</span>
              所属部门
            </template>

            <el-form-item prop="did">
              <el-select
                v-model="dataForm.did"
                clearable
                filterable
                @change="didChange"
                placeholder="请选择部门"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="所属班组">
            <template slot="label">
              <span class="requireSyb">*</span>
              所属班组
            </template>

            <el-form-item prop="tid">
              <el-select
                v-model="dataForm.tid"
                clearable
                filterable
                @change="tidChange"
                :placeholder="dataForm.did ? '请选择班组' : '请先选择部门'"
              >
                <el-option
                  v-for="item in teamList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="所属员工">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              所属员工
            </template>

            <el-form-item prop="employeeIds">
              <el-select
                v-model="dataForm.employeeIds"
                clearable
                filterable
                multiple
                :placeholder="
                  dataForm.did && dataForm.tid
                    ? '请选择员工'
                    : '请先选择部门和班组'
                "
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="车牌号">
            <template slot="label">
              <span class="requireSyb">*</span>
              车牌号
            </template>

            <el-form-item prop="number">
              <el-input
                v-model="dataForm.number"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="车辆识别号">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              车辆识别号
            </template>

            <el-form-item prop="clsbh">
              <el-input
                v-model="dataForm.clsbh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="发动机号">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              发动机号
            </template>

            <el-form-item prop="fdjh">
              <el-input
                v-model="dataForm.fdjh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="购置时间">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              购置时间
            </template>

            <el-form-item prop="gzsj">
              <el-date-picker
                v-model="dataForm.gzsj"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择购置时间"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="退出日期">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              退出日期
            </template>

            <el-form-item prop="tcrq">
              <el-date-picker
                v-model="dataForm.tcrq"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择购置时间"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="生产厂家">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              生产厂家
            </template>

            <el-form-item prop="sccj">
              <el-input
                v-model="dataForm.sccj"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="车型">
            <template slot="label"> 车型 </template>

            <el-form-item prop="chexing">
              <el-input
                v-model="dataForm.chexing"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="能源类型">
            <template slot="label">
              <span class="requireSyb">*</span>
              能源类型
            </template>

            <el-form-item prop="nylx">
              <el-select v-model="dataForm.nylx" clearable>
                <el-option
                  v-for="item in nylxOpt"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <!-- <el-input
                v-model="dataForm.nylx"
                placeholder="请输入"
                clearable
              ></el-input> -->
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="型号">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              型号
            </template>

            <el-form-item prop="xh">
              <el-input
                v-model="dataForm.xh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="外型尺寸">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              外型尺寸
            </template>

            <el-form-item prop="wxcc">
              <el-input
                v-model="dataForm.wxcc"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="座位数">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              座位数
            </template>

            <el-form-item prop="zws">
              <el-input
                v-model="dataForm.zws"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="使用资质">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              使用资质
            </template>

            <el-form-item prop="syzz">
              <el-input
                v-model="dataForm.syzz"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="排放标准">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              排放标准
            </template>

            <el-form-item prop="pwbz">
              <el-input
                v-model="dataForm.pwbz"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="运行路线">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              运行路线
            </template>

            <el-form-item prop="yxlx">
              <el-input
                v-model="dataForm.yxlx"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="车辆品牌">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              车辆品牌
            </template>

            <el-form-item prop="clpp">
              <el-input
                v-model="dataForm.clpp"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="车辆吨位">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              车辆吨位
            </template>

            <el-form-item prop="cldw">
              <el-input
                v-model="dataForm.cldw"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="出厂日期">
            <template slot="label"> 出厂日期 </template>
            <el-form-item prop="chuchangDate">
              <el-date-picker
                v-model="dataForm.chuchangDate"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择出厂日期"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="使用性质">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              使用性质
            </template>

            <el-form-item prop="syxz">
              <el-input
                v-model="dataForm.syxz"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="报废年限">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              报废年限
            </template>

            <el-form-item prop="bfnx">
              <el-input
                v-model="dataForm.bfnx"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="车辆排量">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              车辆排量
            </template>

            <el-form-item prop="clpl">
              <el-input
                v-model="dataForm.clpl"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="所属公司">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              所属公司
            </template>

            <el-form-item prop="ssgs">
              <el-input
                v-model="dataForm.ssgs"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="行驶证号">
            <template slot="label"> 行驶证号 </template>

            <el-form-item prop="xszh">
              <el-input
                v-model="dataForm.xszh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="行驶证发证日期">
            <template slot="label"> 行驶证发证日期 </template>
            <el-form-item prop="xszfzDate">
              <el-date-picker
                v-model="dataForm.xszfzDate"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择发证日期"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="行驶证审验日期">
            <template slot="label"> 行驶证审验日期 </template>
            <el-form-item prop="xszsyDate">
              <el-date-picker
                v-model="dataForm.xszsyDate"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择审验日期"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="道路运输证号">
            <template slot="label"> 道路运输证号 </template>
            <el-form-item prop="dlyszh">
              <el-input
                v-model="dataForm.dlyszh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="道路运输证发证日期">
            <template slot="label"> 道路运输证发证日期 </template>
            <el-form-item prop="dlyszfzDate">
              <el-date-picker
                v-model="dataForm.dlyszfzDate"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择发证日期"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="道路运输证审验日期">
            <template slot="label"> 道路运输证审验日期 </template>
            <el-form-item prop="dlyszsyDate">
              <el-date-picker
                v-model="dataForm.dlyszsyDate"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择审验日期"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="gps品牌">
            <template slot="label"> gps品牌 </template>
            <el-form-item prop="gpspp">
              <el-input
                v-model="dataForm.gpspp"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="gps型号">
            <template slot="label"> gps型号 </template>
            <el-form-item prop="gpsxh">
              <el-input
                v-model="dataForm.gpsxh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="视频监控品牌">
            <template slot="label"> 视频监控品牌 </template>
            <el-form-item prop="spjkpp">
              <el-input
                v-model="dataForm.spjkpp"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="视频监控型号">
            <template slot="label"> 视频监控型号 </template>
            <el-form-item prop="spjkxh">
              <el-input
                v-model="dataForm.spjkxh"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="车辆状态">
            <el-form-item prop="status">
              <el-select v-model="dataForm.status" disabled>
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>

        <el-card style="margin-top: 20px">
          <div slot="header">道路运输证照片</div>

          <el-form-item>
            <el-upload
              :action="$http.common.upload"
              list-type="picture-card"
              :on-success="dlyszPicUpSuccess"
              :headers="{ token: $store.state.token }"
              :on-preview="clickDlyszPic"
              :on-remove="removeDlyszPic"
              :file-list="dlyszPic_arr"
              :limit="1"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-card>

        <el-card style="margin-top: 20px">
          <div slot="header">行驶证照片</div>

          <el-form-item>
            <el-upload
              :action="$http.common.upload"
              list-type="picture-card"
              :on-success="xszPicUpSuccess"
              :headers="{ token: $store.state.token }"
              :on-preview="clickXszPic"
              :on-remove="removeXszPic"
              :file-list="xszPic_arr"
              :limit="1"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-card>

        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form>
    </el-card>

    <!-- 默认车辆信息 -->
    <!-- <basic :propDataForm="dataForm" /> -->

    <!-- 汽车维护 -->
    <maintain
      v-if="comp_visible"
      style="margin-top: 10px"
      :propCarMaintains="dataForm.carMaintains"
      :carId="id"
      @refreshCarInfo="getCarInfo"
    />

    <!-- 汽车维修 -->
    <repair
      v-if="comp_visible"
      style="margin-top: 10px"
      :propCarRepairs="dataForm.carRepairs"
      :carId="id"
      @refreshCarInfo="getCarInfo"
    />

    <div>
      <p>修改记录</p>
      <el-input
        type="textarea"
        v-model="dataForm.log"
        disabled
        rows="6"
      ></el-input>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>
  
<script>
import Basic from "./component/basic";
import Maintain from "./component/maintain";
import Repair from "./component/repair";

import { nylx } from "@/assets/json/index.js";

export default {
  components: {
    Basic,
    Maintain,
    Repair,
  },
  data() {
    return {
      nylxOpt: nylx,
      visible: false,
      comp_visible: false, // 组件显示状态
      statusList: [
        {
          value: 0,
          label: "车辆加入审批中",
        },
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "已拒绝加入",
        },
        {
          value: 3,
          label: "修改车辆信息审批中",
        },
        {
          value: 4,
          label: "退出使用",
        },
      ],
      dataForm: {
        id: "",
        did: "",
        tid: "",
        // eid: "",
        employeeIds: "",
        number: "",
        clsbh: "",
        fdjh: "",
        gzsj: "",
        tcrq: "",
        sccj: "",
        xh: "",
        nylx: "",
        chexing: "",
        wxcc: "",
        zws: "",
        syzz: "",
        pwbz: "",
        yxlx: "",
        dlyszPic: "",
        xszPic: "",
        clpp: "",
        cldw: "",
        chuchangDate: "",
        syxz: "",
        bfnx: "",
        clpl: "",
        ssgs: "",
        xszh: "",
        xszfzDate: "",
        xszsyDate: "",
        dlyszh: "",
        dlyszfzDate: "",
        dlyszsyDate: "",
        gpspp: "",
        gpsxh: "",
        spjkpp: "",
        spjkxh: "",
        status: "",
      },
      dataRule: {
        did: [{ required: true, message: "所属部门不能为空", trigger: "blur" }],
        tid: [{ required: true, message: "所属班组不能为空", trigger: "blur" }],
        number: [
          { required: true, message: "车牌号不能为空", trigger: "blur" },
        ],
        nylx: [
          { required: true, message: "能源类型不能为空", trigger: "blur" },
        ],
      },

      dlyszPic_arr: [], // 道路运输证照片集
      xszPic_arr: [], // 行驶证照片集

      dialogVisible: false, // 显示上传文件的弹窗(显示状态)
      dialogImageUrl: "", // 显示弹窗的图片url

      departmentList: [], // 部门列表
      teamList: [], // 班组列表
      employeeList: [], // 员工列表

      id: "",
      did: "",
      tid: "",
    };
  },
  methods: {
    init(options) {
      this.initData();
      this.id = options.id || "";
      this.dataForm.id = options.id || "";
      this.did = options.did || "";
      this.tid = options.tid || "";
      this.comp_visible = options.id ? true : false;
      this.visible = true;

      // 获取部门列表
      this.getDepartmentList();
      this.getTeamList();
      this.getEmployeeList();

      // 修改页面
      if (this.id) {
        // 获取车辆信息
        this.getCarInfo();
      }

      this.$nextTick(() => {
        this.$refs["dataFormDom"].resetFields();
      });
    },

    // 初始化data
    initData() {
      this.departmentList = [];
      this.teamList = [];
      this.employeeList = [];

      this.dlyszPic_arr = []; // 道路运输证照片集
      this.xszPic_arr = []; // 行驶证照片集
      this.dialogImageUrl = "";
    },

    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.departmentList = res.data.list || [];
        } else {
          this.departmentList = [];
        }
      });
    },
    // 获取班组列表
    getTeamList() {
      let teamListDF = {
        did: this.did,
        page: 1,
        limit: 99999,
      };

      this.$http.team.list(teamListDF).then((res) => {
        if (res.data) {
          this.teamList = res.data.list || [];
        } else {
          this.teamList = [];
        }
      });
    },

    // 获取员工列表
    getEmployeeList() {
      let employeeListDF = {
        did: this.did,
        tid: this.tid,
        page: 1,
        limit: 99999,
      };

      this.$http.employee.list(employeeListDF).then((res) => {
        if (res.data) {
          this.employeeList = res.data.list || [];
        } else {
          this.employeeList = [];
        }
      });
    },

    // 选择的部门发生变化
    didChange() {
      if (this.dataForm.did) {
        this.getTeamList();
      } else {
        this.teamList = [];
        this.dataForm.tid = "";
        this.employeeList = [];
        this.dataForm.employeeIds = [];
      }
    },
    // 选择的班组发生变化
    tidChange() {
      if (this.dataForm.tid) {
        this.getEmployeeList();
      } else {
        this.employeeList = [];
        this.dataForm.employeeIds = [];
      }
    },

    // 提交表单
    dataFormSubmit() {
      this.$refs["dataFormDom"].validate((valid) => {
        if (valid) {
          // 判断是否已经生成车辆数据
          if (!this.comp_visible) {
            this.addCarInfo();
          } else {
            this.updateCarInfo();
          }
        } else {
          this.$message.error("请先完善信息");
        }
      });
    },

    // 修改车辆信息
    updateCarInfo() {
      const _this = this;
      let propData = { ...this.dataForm };

      this.$http.car.update(propData).then((data) => {
        this.$message({
          message: "修改操作成功",
          type: "success",
          duration: 1000,
          onClose() {
            _this.visible = false;
            _this.$emit("refreshDataList");
          },
        });
      });
    },
    // 新增车辆信息
    addCarInfo() {
      const _this = this;
      let propData = { ...this.dataForm };

      this.$http.car.save(propData).then((res) => {
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 1000,
          onClose() {
            _this.comp_visible = true;
            _this.dataForm.id = res.data.id;
            _this.$emit("refreshDataList");
            _this.visible = false;
          },
        });
      });
    },

    // 获取车辆信息
    getCarInfo() {
      this.$http.car.info({ id: this.dataForm.id }).then((res) => {
        this.dataForm = res.data || this.dataForm;
        let employeeIds = [];

        res?.data?.employeeList?.forEach((ele) => {
          employeeIds.push(ele.id);
        });

        if (this.dataForm.dlyszPic) {
          this.dlyszPic_arr = [
            { url: this.$http.common.pic + this.dataForm.dlyszPic },
          ];
        }

        if (this.dataForm.xszPic) {
          this.xszPic_arr = [
            { url: this.$http.common.pic + this.dataForm.xszPic },
          ];
        }
        this.$set(this.dataForm, "employeeIds", employeeIds);
        console.log(this.dataForm, "dataForm");
        // this.getDepartmentList();
        // this.getTeamList();
        // this.getEmployeeList();
      });
    },

    // 文件上传成功(道路运输证照片)
    dlyszPicUpSuccess(file) {
      console.log(file);
      if (file.code === 0) {
        this.dialogImageUrl = this.$http.common.pic + file.data;
        this.dataForm.dlyszPic = file.data;
      }
    },
    // 点击上传成功的文件(道路运输证照片)
    clickDlyszPic() {
      if (this.dialogImageUrl) {
        this.dialogVisible = true;
      }
    },
    // 删除上传文件(道路运输证照片)
    removeDlyszPic() {
      this.dataForm.dlyszPic = "";
    },

    // 文件上传成功(行驶证照片)
    xszPicUpSuccess(file) {
      if (file.code === 0) {
        this.dialogImageUrl = this.$http.common.pic + file.data;
        this.dataForm.xszPic = file.data;
      }
    },
    // 点击上传成功的文件(行驶证照片)
    clickXszPic() {
      if (this.dialogImageUrl) {
        this.dialogVisible = true;
      }
    },
    // 删除上传文件(行驶证照片)
    removeXszPic() {
      this.dataForm.xszPic = "";
    },
  },
};
</script>
  
<style lang="scss" scoped>
:deep() {
  .el-descriptions {
    .el-descriptions-item__label {
      width: 100px;
      text-align: center;
      color: #606266;
    }

    .el-descriptions-item__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>